.NavBar-strip button:focus {
  outline: none;
}

.eds-button--secondary {
  background: none;
}

#product-attributes-search-wrapper .AuiInputLabel {
  display: none;
}

.eds-side-panel {
  align-content: space-between;
  background-color: var(--eds-color-white);
  box-shadow: var(--eds-elevation-shadow-1);
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  min-width: 448px;
  max-width: 600px;
  padding: var(--eds-space-32);
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 56px);
}

.eds-side-panel__header {
  display: flex;
  margin: 0 0 var(--eds-space-24) 0;
}

.eds-side-panel__header > *:first-child {
  flex-grow: 1;
}

.eds-side-panel__content {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.eds-side-panel__content:before {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  display: block;
  height: 90px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.eds-side-panel__scrolltop:before {
  display: none;
} 

.eds-side-panel__content:after {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 90px;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.eds-side-panel__scrollbottom:after {
  display: none;
} 

.eds-side-panel__content > div {
  height: 100%;
  overflow-y: scroll;
}

.eds-side-panel__close {
  color: var(--eds-color-grey-1);
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.eds-side-panel__divider {
  background-color: var(--eds-color-grey-2);
  height: 1px;
  margin: var(--eds-space-24) 0; 
}

.eds-chip {
  align-items: center;
  background-color: var(--eds-color-grey-3);
}

.eds-chip:hover {
  background-color: var(--eds-color-grey-2);
}

.eds-accordion__divider {
  background-color: var(--eds-color-grey-2);
  height: 1px;
  margin: var(--eds-space-24) 0; 
}

.eds-accordion__header {
  display: flex;
}

.eds-accordion__title {
  flex-grow: 1;
}